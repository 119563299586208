import SearchFormDesktopClient from "@app/modules/search/search-form-desktop-client";
import SearchFormMobileClient from "@app/modules/search/search-form-mobile-client";

import { BB, buildLandingStore } from "../setups/landing";

// Defines the search form state for the landing pages
if (!window.store) {
  window.store = buildLandingStore();
}

const options = {
  napi_url: BB.NAPI_URL,
  lang: BB.config.lang,
  locale: BB.config.locale,
  initial_state: BB.search_form,
  features: BB.features,
  tracker: window.tracker,
  whitelabel: BB.whitelabel,
  push_state_disabled: BB.push_state_disabled,
  geo_data: window.__GEO_DATA__
};

function initializeSearchForm(is_mobile: boolean): void {
  if (is_mobile) {
    new SearchFormMobileClient(options);
  } else {
    new SearchFormDesktopClient(options);
  }
}

const is_new_search_form_enabled: boolean = !!BB.features.SEARCH_FORM_HYDRATION;
if (!is_new_search_form_enabled) {
  initializeSearchForm(BB.device.is_mobile);
}
