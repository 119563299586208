export const AUTOCOMPLETE_DEBOUNCE_MS = 150;
export const AUTOCOMPLETE_SPINNER_DELAY_MS = 200;
export const AUTOCOMPLETE_SPINNER_MIN_DURATION_MS = 150;

export const HEADER_COUNTRY = "HEADER_COUNTRY";
export const HEADER_CITY = "HEADER_CITY";
export const HEADER_BUS_STOP = "HEADER_BUS_STOP";
export const HEADER_PARTNER = "HEADER_PARTNER";
export const HEADER_HOME = "HEADER_HOME";
export const HEADER_ROUTE = "HEADER_ROUTE";

export const HOTELSCOMBINED_COUNTRIES = ["FR", "ES", "GB"];
export const AIRBNB_COUNTRIES = [
  "AR",
  "BE",
  "BR",
  "CL",
  "CO",
  "DE",
  "ES",
  "FR",
  "GB",
  "IT",
  "MX",
  "PE"
];

export const SEARCH_ERRORS = {
  MISSING_ORIGIN: "missing_origin",
  MISSING_DESTINATION: "missing_destination",
  MISSING_OUTBOUND: "missing_outbound",
  RETURN_BEFORE_OUTBOUND: "return_before_outbound",
  CHILD_ALONE: "child_alone",
  MISSING_AGE: "missing_age"
} as const;

export const MAX_SEARCH_DISTANCE_MONTHS = 6;

export const VALIDATE_FORM_MESSAGE = "VALIDATE_SEARCH_FORM";
