import { PlaceType } from "@busbud/core-types/dist/lib/flex";

import { LocalStorage } from "@app/lib/bowser-storage/local-storage";
import { CitySuggestionTemplate } from "@app/modules/search/autocomplete/autocomplete-client";
import {
  isPlaceSearchAlreadySaved,
  recent_searches_keys
} from "@app/modules/search/helpers/recent-searches";
import { AttributePassengerFields } from "@app/modules/search/store/slices/search-form/reducers/unassign-attribute-to-passenger";
import {
  SearchLocationType,
  SearchPlace,
  SearchReduxStateWithRoute
} from "@app/types/search-types";
import { AmplitudeEvent } from "@app/types/tracking/common";
import {
  AffiliateRedirected,
  BaseSearchRouteDetails,
  ClickedAffiliateCheckbox,
  ClickedReverseCities,
  ClickedSearchDestination,
  ClickedSearchOrigin,
  ClickedSearchOutboundDate,
  ClickedSearchPassenger,
  ClickedSearchReturnDate,
  FailedRouteSearch,
  InvalidCityError,
  OpenedSearchDestination,
  SearchedFromTrainTicketsPage,
  SearchedRoute,
  SelectedDepartureDate,
  SelectedDestination,
  SelectedOrigin,
  SelectedReturnDate,
  ToggledSearchVisibility,
  UnselectedReturnDate
} from "@app/types/tracking/search";

import * as PropertyValues from "./constants/property-values";
import { TrackingAction } from "./constants/tracking-action";

const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;

export function clickedReverseCities(
  origin: SearchPlace | null,
  destination: SearchPlace | null
): AmplitudeEvent<ClickedReverseCities> {
  const origin_url_form = origin
    ? origin.city.url
    : PropertyValues.EMPTY_STRING;
  const destination_url_form = destination
    ? destination.city.url
    : PropertyValues.EMPTY_STRING;

  return {
    action: TrackingAction.ClickedReverseCities,
    details: {
      origin_route: `${origin_url_form}/${destination_url_form}`,
      new_route: `${destination_url_form}/${origin_url_form}`
    }
  };
}

export function clickedAffiliateCheckbox(
  partner: string,
  is_checked: boolean
): AmplitudeEvent<ClickedAffiliateCheckbox> {
  return {
    action: TrackingAction.ClickedAffiliateCheckbox,
    details: { partner, is_checked }
  };
}

export function clickedSearchInput(
  type: SearchLocationType
): AmplitudeEvent<ClickedSearchOrigin | ClickedSearchDestination> {
  const is_origin = type === "origin";
  const action = is_origin
    ? TrackingAction.ClickedSearchOrigin
    : TrackingAction.ClickedSearchDestination;

  return {
    action,
    details: {}
  };
}

export function openedSearchDestination(): AmplitudeEvent<OpenedSearchDestination> {
  return {
    action: TrackingAction.OpenedSearchDestination,
    details: {}
  };
}

export function clickedSearchDate(
  direction: "outbound" | "return"
): AmplitudeEvent<ClickedSearchOutboundDate | ClickedSearchReturnDate> {
  const action =
    direction === "outbound"
      ? TrackingAction.ClickedSearchOutboundDate
      : TrackingAction.ClickedSearchReturnDate;
  return {
    action,
    details: {}
  };
}

export function unselectedReturnDate(): AmplitudeEvent<UnselectedReturnDate> {
  return {
    action: TrackingAction.UnselectedReturnDate,
    details: {}
  };
}

export function clickedSearchPassenger(): AmplitudeEvent<ClickedSearchPassenger> {
  return {
    action: TrackingAction.ClickedSearchPassenger,
    details: {}
  };
}

type TrackingSearchState = Pick<
  SearchReduxStateWithRoute,
  "origin" | "destination" | "outbound_date" | "return_date"
> &
  Pick<
    AttributePassengerFields,
    | "adult"
    | "child"
    | "senior"
    | "adult_wheelchair"
    | "child_wheelchair"
    | "senior_wheelchair"
  >;

interface SearchedRouteTrackingParams {
  state: TrackingSearchState;
  initial_origin: SearchPlace | null;
  initial_destination: SearchPlace | null;
  search_with_accomodation_affiliate: string;
}

export function searchedRoute({
  state,
  initial_origin,
  initial_destination,
  search_with_accomodation_affiliate
}: SearchedRouteTrackingParams): AmplitudeEvent<SearchedRoute> {
  const { origin, destination } = state;

  const details = formatSearchStateDetails({
    state,
    search_with_accomodation_affiliate
  });

  return {
    category: "search",
    action: TrackingAction.SearchedRoute,
    details: {
      ...details,
      search_changed_route: getSearchRouteChanges(
        origin,
        destination,
        initial_origin,
        initial_destination
      )
    },
    gtm_props: {
      origin_geohash: origin.city.geohash,
      destination_geohash: destination.city.geohash
    }
  };
}

interface FailedRouteSearchParams {
  state: TrackingSearchState;
  errors: string[];
  search_with_accomodation_affiliate: string;
}

export function failedRouteSearch({
  state,
  errors,
  search_with_accomodation_affiliate
}: FailedRouteSearchParams): AmplitudeEvent<FailedRouteSearch> {
  const details = formatSearchStateDetails({
    state,
    search_with_accomodation_affiliate
  });

  return {
    category: "search",
    action: TrackingAction.FailedRouteSearch,
    details: { ...details, search_errors: errors }
  };
}

export function selectedOrigin(
  place: SearchPlace
): AmplitudeEvent<SelectedOrigin> {
  const [city = "", region = "", country = ""] =
    place.city?.full_name?.split(", ") || [];
  const saved_recent_searches: CitySuggestionTemplate[] =
    LocalStorage.fromWindow(window).getItem(recent_searches_keys["origin"]) ||
    [];
  const is_recent_search = isPlaceSearchAlreadySaved(
    saved_recent_searches,
    place
  );

  return {
    action: TrackingAction.SelectedOrigin,
    details: {
      place_type: place.location?.place_type || PlaceType.City,
      stop_type: place.location?.stop_type,
      full_name: place.location?.full_name,
      city,
      region,
      country,
      is_recent_search
    }
  };
}

export function selectedDestination(
  place: SearchPlace
): AmplitudeEvent<SelectedDestination> {
  const [city = "", region = "", country = ""] =
    place.city?.full_name?.split(", ") || [];
  const saved_recent_searches: CitySuggestionTemplate[] =
    LocalStorage.fromWindow(window).getItem(
      recent_searches_keys["destination"]
    ) || [];
  const is_recent_search = isPlaceSearchAlreadySaved(
    saved_recent_searches,
    place
  );

  return {
    action: TrackingAction.SelectedDestination,
    details: {
      suggestion_type: "autocomplete",
      suggestion_source: undefined,
      location_type: undefined,
      location_name: undefined,
      place_type: place.location?.place_type || PlaceType.City,
      stop_type: place.location?.stop_type,
      full_name: place.location?.full_name,
      city,
      region,
      country,
      is_recent_search: is_recent_search
    }
  };
}

export function invalidCityError(
  error_context: InvalidCityError
): AmplitudeEvent<InvalidCityError> {
  return {
    action: TrackingAction.InvalidCityError,
    details: error_context
  };
}

/**
 * @param previous_date (YYYY-MM-DD)
 * @param selected_date (YYYY-MM-DD)
 * @returns
 */
export function selectedDepartureDate(
  previous_date: string | null,
  selected_date: string
): AmplitudeEvent<SelectedDepartureDate> {
  return {
    action: TrackingAction.SelectedDepartureDate,
    details: {
      previous_selection: previous_date,
      new_selection: selected_date
    }
  };
}

/**
 * @param previous_date (YYYY-MM-DD)
 * @param selected_date (YYYY-MM-DD)
 * @returns
 */
export function selectedReturnDate(
  previous_date: string | null,
  selected_date: string
): AmplitudeEvent<SelectedReturnDate> {
  return {
    action: TrackingAction.SelectedReturnDate,
    details: {
      previous_selection: previous_date,
      new_selection: selected_date
    }
  };
}

function getDaysFromToday(date: string): number {
  const today = Date.now();
  const date_time = Date.parse(date);
  const date_diff = (date_time - today) / MILLISECONDS_PER_DAY;

  return Math.ceil(date_diff);
}

interface FormatSearchStateDetailsParams {
  state: TrackingSearchState;
  search_with_accomodation_affiliate: string;
}

function formatSearchStateDetails({
  state,
  search_with_accomodation_affiliate
}: FormatSearchStateDetailsParams): BaseSearchRouteDetails {
  const {
    origin,
    destination,
    outbound_date,
    return_date,
    adult,
    child,
    senior,
    adult_wheelchair,
    child_wheelchair,
    senior_wheelchair
  } = state;
  let route = "no-route";

  if (origin && destination) {
    route = `${origin.city.full_name}/${destination.city.full_name}`;
  }

  const origin_country = origin
    ? (origin.city.full_name.split(",").pop() as string)
    : PropertyValues.EMPTY_STRING;
  const destination_country = destination
    ? (destination.city.full_name.split(",").pop() as string)
    : PropertyValues.EMPTY_STRING;

  const days_until_outbound_date = getDaysFromToday(outbound_date);
  const days_until_return_date = return_date
    ? getDaysFromToday(return_date)
    : PropertyValues.NA_NUMBER;

  let days_of_travel: number | undefined;

  if (days_until_outbound_date && days_until_return_date) {
    days_of_travel = days_until_return_date - days_until_outbound_date;
  }

  const wheelchair_count =
    adult_wheelchair + child_wheelchair + senior_wheelchair;

  return {
    search_origin: origin ? origin.city.full_name : PropertyValues.EMPTY_STRING,
    search_origin_location:
      origin && origin.location
        ? origin.location.full_name
        : PropertyValues.EMPTY_STRING,
    search_origin_country: origin_country,
    search_destination: destination
      ? destination.city.full_name
      : PropertyValues.EMPTY_STRING,
    search_destination_country: destination_country,
    search_destination_location:
      destination && destination.location
        ? destination.location.full_name
        : PropertyValues.EMPTY_STRING,
    search_route: route,
    search_adults_count: adult,
    search_seniors_count: senior,
    search_children_count: child,
    search_passengers_count: adult + senior + child,
    search_date: outbound_date,
    search_return_date: return_date,
    search_days_of_travel: days_of_travel,
    search_days_to_departure: days_until_outbound_date,
    search_days_to_return: days_until_return_date,
    search_trip_type: outbound_date && return_date ? "roundtrip" : "one-way",
    search_passengers_type: [adult, child, senior].join(""),
    search_wheelchair_count: wheelchair_count,
    search_with_accomodation_affiliate
  };
}

function getSearchRouteChanges(
  origin: SearchPlace,
  destination: SearchPlace,
  initial_origin: SearchPlace | null,
  initial_destination: SearchPlace | null
): ("origin" | "destination")[] {
  const changed_route: ("origin" | "destination")[] = [];

  const origin_changed =
    initial_origin &&
    origin &&
    initial_origin.city.geohash !== origin.city.geohash;

  const destination_changed =
    initial_destination &&
    destination &&
    initial_destination.city.geohash !== destination.city.geohash;

  if (origin_changed) {
    changed_route.push("origin");
  }

  if (destination_changed) {
    changed_route.push("destination");
  }

  return changed_route;
}

export function toggledSearchVisibility(
  target: string,
  expanded: string,
  toggle_trigger?: string
): AmplitudeEvent<ToggledSearchVisibility> {
  return {
    action: TrackingAction.ToggledSearchVisibility,
    details: {
      toggle_trigger,
      target,
      expanded
    }
  };
}

export function searchFromTrainTicketsPage(): AmplitudeEvent<SearchedFromTrainTicketsPage> {
  return {
    action: TrackingAction.SearchedFromTrainTicketsPage,
    details: {}
  };
}

export function redirectToAffiliatePartner(
  affiliate: string,
  source: string
): AmplitudeEvent<AffiliateRedirected> {
  return {
    action: TrackingAction.AffiliateRedirected,
    details: {
      affiliate,
      source
    }
  };
}
