import "./includes/search";
import "./includes/core-vanilla";
import "./includes/activity";
import "./includes/header-desktop";

import React from "react";

import { hydrateRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { initQuickMenuTracking } from "@app/components/landing-pages/quick-access-menu/quick-access-menu-client";
import { ModalManagerLanding } from "@app/components/modals";
import {
  LazyCalendarModal,
  LazyPassengersSelector
} from "@app/components/modals/modal-manager-landing";
import { ThemeProviderClient } from "@app/context/theme-provider-client";
import { PriceCalendarBehaviour } from "@app/modules/price-calendar/price-calendar-behaviour";
import { Reviews } from "@app/modules/reviews";
import { TRUSTPILOT_WIDGET_CLASS } from "@app/modules/trustpilot/components/trustpilot-constants";
import { LiteServiceProviderClient } from "@app/services/context-providers/lite-service-provider-client-base";
import hotjar from "@app/tracking/hotjar";
import { acceptHotReplacement } from "@app/utils/hmr";

acceptHotReplacement(module);

if (window.BB.whitelabel_shared_info) {
  window.localStorage.setItem(
    `${window.BB.whitelabel}_shared_info`,
    JSON.stringify(window.BB.whitelabel_shared_info)
  );
}

document.addEventListener("DOMContentLoaded", () => {
  // Bypass third party scripts for testing purposes
  const urlParams = new URLSearchParams(window.location.search);
  const disable_third_party_scripts = urlParams.get(
    "disable_third_party_scripts"
  );

  const passengerInput = document.getElementById("passenger-input");
  if (passengerInput) {
    function handlePreloadPassengerChunk() {
      passengerInput?.removeEventListener(
        "mouseenter",
        handlePreloadPassengerChunk
      );
      LazyPassengersSelector.preload();
    }

    // Passenger selector starts loading when the user hovers over the input, or when the page is finished loading
    passengerInput.addEventListener("mouseenter", handlePreloadPassengerChunk);
    window.addEventListener("load", handlePreloadPassengerChunk);
  }

  setTimeout(() => {
    // Loaded when the main thread is free
    LazyCalendarModal.preload();

    setTimeout(() => {
      if (disable_third_party_scripts !== "all") {
        Reviews.init(window.tracker);
        new PriceCalendarBehaviour().init(window.tracker, window.document);

        initQuickMenuTracking(window.tracker);

        const trustpilot = window.Trustpilot;
        if (trustpilot) {
          const trustboxes = document.querySelectorAll<HTMLElement>(
            `.${TRUSTPILOT_WIDGET_CLASS}`
          );
          trustboxes.forEach(trustbox => {
            trustpilot.loadFromElement(trustbox);
          });
        }

        if (window.BB.experiments.auto_complete_suggestions === "1") {
          try {
            hotjar.trigger("autocomplete");
          } catch (err) {}
        }

        import(
          /* webpackChunkName: "web-vitals" */ "./includes/web-vitals-reporting"
        ).then(({ WebVitalsReporting }) => {
          new WebVitalsReporting(
            window.BB.inst.request_id,
            window.BB.experiments,
            window.BB.entity,
            window.BB.config.locale,
            window.BB.device
          );
        });
      }
    }, 1);
  }, 1);
});

(function hydrateLandingPage() {
  const root_id = "modal-manager";
  const react_content = document.getElementById(root_id);

  if (!react_content) {
    return;
  }

  hydrateRoot(
    react_content,
    <ThemeProviderClient>
      <Provider store={window.store}>
        <LiteServiceProviderClient BB={window.BB} I18N={window.__I18N__}>
          <ModalManagerLanding />
        </LiteServiceProviderClient>
      </Provider>
    </ThemeProviderClient>
  );
})();
