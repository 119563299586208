import type { Tracker } from "@app/tracking/tracker";

import { MIN_NUM_REVIEWS } from "../../constants/rating";
import {
  clickedReadLessReviews,
  clickedReadMoreReviews
} from "../../tracking/reviews-tracking";

export const Reviews = new (class {
  private tracker: Tracker | undefined;
  private expand_link_elem: HTMLElement | null = null;
  private collapse_link_elem: HTMLElement | null = null;
  private review_elems: HTMLElement[] = [];

  public init(tracker: Tracker) {
    this.tracker = tracker;
    this.expand_link_elem = document.getElementById("expand-reviews");
    this.collapse_link_elem = document.getElementById("collapse-reviews");

    this.review_elems = [].slice.call(
      document.getElementsByClassName("js-review")
    );

    this._initReviewLinks();
  }

  private _initReviewLinks() {
    // Link won't be displayed if minimum number of reviews isn't reached.
    if (!this.expand_link_elem) {
      return null;
    }

    this.expand_link_elem.addEventListener("click", e => {
      e.preventDefault();
      if (this.expand_link_elem) {
        this.expand_link_elem.style.display = "none";
      }
      if (this.collapse_link_elem) {
        this.collapse_link_elem.style.display = "inline-flex";
      }

      this.review_elems.forEach(item => {
        item.classList.remove("hidden");
        item.classList.add("grid");
      });

      this.tracker?.track(clickedReadMoreReviews());
    });

    this.collapse_link_elem?.addEventListener("click", e => {
      e.preventDefault();

      if (this.collapse_link_elem) {
        this.collapse_link_elem.style.display = "none";
      }
      if (this.expand_link_elem) {
        this.expand_link_elem.style.display = "inline-flex";
      }

      this.review_elems.forEach((item, index) => {
        if (index >= MIN_NUM_REVIEWS) {
          item.classList.add("hidden");
        }
      });

      this.tracker?.track(clickedReadLessReviews());
    });
  }
})();
